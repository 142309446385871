<template>
  <div
    v-if="visibleSteps.length"
    class="flex h-11 items-center gap-x-2 overflow-hidden"
  >
    <PanelNavigationDropdown :panels :set-panel :step class="h-full" />
    <div v-for="{ key, title } in visibleSteps" :key class="flex">
      <div class="flex-1 truncate" @click="handleClick(key)" v-text="title" />

      <div class="flex h-full w-9 items-center justify-center">
        <InlineSvg :name="SVG_ICON.CHEVRON_RIGHT" class="size-6 fill-current" />
      </div>
    </div>
    <div class="flex" v-text="activePanel?.title" />
  </div>
</template>

<script setup lang="ts">
import type { Panel } from '@base/types/panel-manager'
import type { VoucherShopStepKey } from '@base/types/voucher-shop'

interface Props {
  panels: Panel[]
  setPanel: (panel: VoucherShopStepKey, options?: any) => void
  activePanel?: Panel
  step: string
}

const props = defineProps<Props>()

const currentStepIndex = computed(() =>
  props.panels.findIndex((s) => s.key === props.step)
)

function handleClick(key: string) {
  props.setPanel(key as VoucherShopStepKey)
}

const visibleSteps = computed(() =>
  props.panels
    ?.slice(0, currentStepIndex.value)
    .filter(({ hideFromNav }) => !hideFromNav)
)

defineOptions({
  name: 'PanelNavigation',
})
</script>
