<template>
  <div>
    <FormKit
      type="checkbox"
      name="termsOfService"
      :label="t('termsOfService')"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

defineOptions({
  name: 'CartTermsOfService',
})
</script>

<i18n>
de:
  termsOfService: "AGB"
es:
  termsOfService: "Términos de servicio"
</i18n>
