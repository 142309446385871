<template>
  <div>
    <BookingHeading :title="t('contact')" />
    <p v-text="t('info')" />
    <FormKit type="email" name="email" :label="t('email')" :help="t('legal')" />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

defineOptions({
  name: 'CartContact',
})
</script>

<i18n>
de:
  contact: "Kontakt"
  info: "Die angegebene E-Mail-Adresse wird zum Versand der Bestellung und der Bestellbestätigung verwendet."
  email: "E-Mail-Adresse"
  legal: "Einer Verwendung zur weiteren Kundenansprache nach § 7 Abs. 2 Nr. 3 UWG kann jederzeit kostenlos widersprochen werden."
es:
  contact: "Contacto"
  info: "La dirección de correo electrónico se utiliza para enviar la orden y la confirmación de la orden."
  email: "Correo electrónico"
  legal: "El uso para contactar al cliente después de § 7 Abs. 2 Nr. 3 UWG puede ser retirado en cualquier momento de forma gratuita."
</i18n>
