import { CART_STRINGS, CART_TYPE } from '@base/constants/cart'
import type { CustomisedVoucherProduct } from '@base/types/voucher-shop'
import type {
  CartVoucherProductInput,
  Cart,
  CartType,
  Cinema,
} from '#gql/default'

const { UPDATE_CART_ERROR, ADDED_TO_CART, UPDATED_IN_CART, DELETED_FROM_CART } =
  CART_STRINGS

interface AddToCartPayload {
  quantity: number
  selectedAmount: number
  voucherDesignId: string
  cartVoucherId?: string
  voucherProduct: CustomisedVoucherProduct
  locale: string
  onSuccess?: () => void
  onError?: () => void
}

export default async function useVoucherCart({
  cinema,
  onReset,
}: {
  cinema?: Cinema
  onReset?: () => void
}) {
  const { ct, fetchCinemaStrings } = useCinemaStrings()
  const { add: addMessage } = useMessage()
  const { cart, fetchCart, ensureCart } = await useCart({
    onReset,
  })

  const pending = useState('voucherCartPending', () => false)

  async function getCartVoucher(cartVoucherId: string) {
    pending.value = true
    try {
      const cart = await fetchCart()
      return cart?.voucherProducts.find(({ id }) => id === cartVoucherId)
    } finally {
      pending.value = false
    }
  }

  async function patchVoucherProducts(
    voucherProducts: CartVoucherProductInput[]
  ): Promise<Cart> {
    pending.value = true
    try {
      let cartId = cart.value?.id

      if (!cartId) {
        const { id } = await ensureCart({
          cinema,
          type: CART_TYPE.VOUCHER as CartType,
        })
        cartId = id
      }

      const result = await GqlCartPatchVoucherProducts({
        cartId,
        voucherProducts,
      })

      const updatedCart = result.cartPatchVoucherProducts as Cart
      cart.value = updatedCart

      return updatedCart
    } catch (error) {
      throw new Error(error as string)
    } finally {
      pending.value = false
    }
  }

  function getCartUpdateMessage(
    quantity: number,
    cartVoucherId: string | undefined,
    productName: string
  ): string {
    if (quantity === 0) {
      return ct(DELETED_FROM_CART, { item: productName })
    }
    if (!cartVoucherId) {
      return ct(ADDED_TO_CART, {
        quantity: quantity.toString(),
        item: productName,
      })
    }
    return ct(UPDATED_IN_CART, {
      quantity: quantity.toString(),
      item: productName,
    })
  }

  async function addToCart(payload: AddToCartPayload) {
    await fetchCinemaStrings({
      keys: [
        UPDATE_CART_ERROR,
        DELETED_FROM_CART,
        UPDATED_IN_CART,
        ADDED_TO_CART,
      ],
      cinemaId: cinema?.id,
    })

    const {
      quantity,
      selectedAmount,
      voucherDesignId,
      cartVoucherId,
      voucherProduct,
      locale,
      onSuccess,
      onError,
    } = payload

    try {
      await patchVoucherProducts([
        {
          id: cartVoucherId,
          voucherProductId: voucherProduct.id,
          customisations: voucherProduct.customisations,
          voucherDesignId,
          locale,
          quantity,
          selectedAmount,
        },
      ])

      addMessage({
        message: getCartUpdateMessage(
          quantity,
          cartVoucherId,
          voucherProduct.name
        ),
        severity: MESSAGE_SEVERITY.SUCCESS,
        type: MESSAGE_TYPE.TOAST,
      })
      onSuccess?.()
    } catch (error) {
      addMessage({
        message: ct(UPDATE_CART_ERROR),
        severity: MESSAGE_SEVERITY.ERROR,
        type: MESSAGE_TYPE.TOAST,
      })
      onError?.()
    }
  }

  return {
    cart,
    patchVoucherProducts,
    getCartVoucher,
    addToCart,
    pending,
  }
}
