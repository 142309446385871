export const CART_STRINGS = {
  ADDED_TO_CART: 'NUXT_ITEM_ADDED_TO_CART',
  UPDATED_IN_CART: 'NUXT_ITEM_UPDATED_IN_CART',
  DELETED_FROM_CART: 'NUXT_ITEM_DELETED_FROM_CART',
  UPDATE_CART_ERROR: 'NUXT_UPDATE_CART_ERROR',
} as const

export const CART_TYPE = {
  VOUCHER: 'VOUCHER',
} as const
