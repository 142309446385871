<template>
  <Dropdown :open="isOpen" @open="isOpen = true" @close="isOpen = false">
    <template #trigger>
      <button
        class="dark:bg-neutral-dark-2 dark:text-light h-full w-11 rounded-md"
        @click="isOpen = !isOpen"
        v-text="'...'"
      />
    </template>

    <template #default>
      <div class="px-4 md:max-h-96">
        <div
          v-for="{ key, title } in visibleSteps"
          :key
          class="py-1"
          @click="handleClick(key)"
        >
          {{ title }}
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import type { VoucherShopStepKey } from '@base/types/voucher-shop'
import type { Panel } from '@base/types/panel-manager'

interface Props {
  panels: Panel[]
  setPanel: (panel: VoucherShopStepKey, options?: any) => void
  step: string
}

const { panels, setPanel, step } = defineProps<Props>()

function handleClick(key: string) {
  setPanel(key as VoucherShopStepKey)
  isOpen.value = false
}

const currentStepIndex = computed(() => panels.findIndex((s) => s.key === step))
const visibleSteps = computed(() => panels.slice(0, currentStepIndex.value))

const isOpen = ref(false)
defineOptions({
  name: 'PanelNavigationDropdown',
})
</script>
