<template>
  <div class="w-full">
    <p class="mb-4" v-text="t('payEasyAndSecure')" />

    <template v-for="{ name: key } in availablePaymentMethods">
      <InlineSvg
        v-if="getPaymentIcon(key)"
        :key="key"
        v-bind="getPaymentIcon(key)"
        class="mb-2 mr-4"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
interface Props {
  availablePaymentMethods: any[]
}

const { availablePaymentMethods } = defineProps<Props>()
const { t } = useI18n()
const { getPaymentIcon } = usePaymentIcons()

defineOptions({
  name: 'CartAvailablePaymentMethods',
})
</script>

<i18n>
de:
  payEasyAndSecure: "Einfach und sicher bezahlen, mit"
es:
  payEasyAndSecure: "Paga fácil y seguro, con"
</i18n>
