<template>
  <div id="panel-manager" class="space-y-4">
    <div class="py-2">
      <slot name="navigation" :step />
    </div>
    <div>
      <slot name="panels" :step />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const step = useRouteQuery('step', '', {
  transform: (value) => value as string,
})

function scrollToPanelManager() {
  document
    .getElementById('panel-manager')
    ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

watch(step, scrollToPanelManager)

defineOptions({
  name: 'PanelManager',
})
</script>
