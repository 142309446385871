<template>
  <div>
    <BookingButton
      :label="t('btn.label')"
      :description="t('btn.description')"
      :prefix-icon="SVG_ICON.SQUARE_EDIT_OUTLINE"
      :suffix-icon="SVG_ICON.CHEVRON_RIGHT"
      @click="isModalOpen = true"
    />

    <LazyVoucherProductFormConfigureShippingModal
      v-if="isModalOpen"
      :index
      @close="isModalOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  index: number
}

const { index } = defineProps<Props>()

const isModalOpen = ref(false)
const { t } = useI18n()

defineOptions({
  name: 'VoucherProductFormConfigureShipping',
})
</script>

<i18n>
de:
  btn:
    label: "Versand"
    description: "sofort, an die Rechnungsadresse"
es:
  btn:
    label: "Envío"
    description: "inmediato, a la dirección de facturación"
</i18n>
