<template>
  <Button
    :title="t('btn.cart')"
    :icon="SVG_ICON.SHOPPING_OUTLINE"
    class="fixed bottom-4 right-4"
  />
</template>

<script setup lang="ts">
const { t } = useI18n()

defineOptions({
  name: 'CartFab',
})
</script>

<i18n>
de:
  btn:
    cart: "Warenkorb"
es:
  btn:
    cart: "Carrito"
</i18n>
