export const VOUCHER_SHOP_PANEL = {
  SELECT: 'select',
  CONFIGURE: 'configure',
  CART: 'cart',
  CHECKOUT: 'checkout',
} as const

export const VOUCHER_SHOP_QUERY_PARAM = {
  STEP: 'step',
  VOUCHER_ID: 'voucherId',
  CART_VOUCHER_ID: 'cartVoucherId',
} as const

export const VOUCHER_PRODUCT_TYPE = {
  FIXED: 'FIXED',
  TICKETS: 'TICKETS',
} as const
