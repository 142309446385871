<template>
  <div>
    <BookingButton
      :label="t('btn.label')"
      :description="
        personalisation ?? `${t('btn.description')} ${t('optional')}`
      "
      :prefix-icon="SVG_ICON.SQUARE_EDIT_OUTLINE"
      :suffix-icon="SVG_ICON.CHEVRON_RIGHT"
      @click="isModalOpen = true"
    />

    <LazyVoucherProductFormConfigurePersonalisationModal
      v-if="isModalOpen"
      :key="index"
      :index
      :personalisation
      @close="isModalOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  index: number
}

const isModalOpen = ref(false)
const { index } = defineProps<Props>()
const { t } = useI18n()
const { voucherProduct } = await useVoucherShop()

const personalisation = computed(
  () => voucherProduct.value?.customisations?.[index]?.text
)

defineOptions({
  name: 'VoucherConfigurePersonalisation',
})
</script>

<i18n>
de:
  optional: "(optional)"
  btn:
    label: "Personalisierung"
    description: "Persönliche Nachricht hinzufügen"
es:
  optional: "(opcional)"
  btn:
    label: "Personalización"
    description: "Agregar mensaje personalizado"
</i18n>
