<template>
  <div
    class="flex select-none items-center gap-2 overflow-hidden rounded-md border p-2 transition-colors duration-300"
    :class="cssClasses"
  >
    <div class="relative z-20 flex items-center gap-2">
      <InlineSvg :name="SVG_ICON.CALENDAR" class="size-6 fill-current" />
      <span v-text="t('reservationTime')" />
      <span class="tabular-nums" v-text="formattedTime()" />
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  expiresAt: string
}

const props = defineProps<Props>()
const { t } = useI18n()

const { timeUnits, timeLeft } = useCountdown({
  expiresAt: computed(() => props.expiresAt),
})

function formattedTime() {
  if (timeLeft.value === 0) {
    return '00:00:00'
  }
  const { hours, minutes, seconds } = timeUnits.value
  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':')
}

const cssClasses = computed(() => ({
  'border-system-success bg-system-success/80 text-on-system-success':
    timeLeft.value > 140,
  'border-system-info bg-system-info/80 text-on-system-info':
    timeLeft.value < 140 && timeLeft.value > 40,
  'border-system-error bg-system-error/80 text-on-system-error':
    timeLeft.value <= 40,
}))

defineOptions({ name: 'Countdown' })
</script>

<i18n>
de:
  reservationTime: "Reservierungszeit:"
es:
  reservationTime: "Tiempo de reserva:"
</i18n>
