<template>
  <Panel>
    <template #default="{ cssClasses }">
      <div :class="cssClasses.grid">
        <CartCountdown
          v-if="cart?.expiresAt"
          :expires-at="cart.expiresAt"
          :class="[cssClasses.sidebar, 'order-1 md:order-2']"
        />

        <CartOverview :class="[cssClasses.sidebar, 'order-3']" />

        <div :class="[cssClasses.content, 'order-2 md:order-1 md:row-span-4']">
          <FormKit
            type="form"
            :actions="false"
            :classes="{ form: '$reset space-y-4' }"
          >
            <CartContact />
            <CartPaymentSelection />
            <CartTermsOfService />
          </FormKit>
        </div>

        <div :class="[cssClasses.content, 'order-last md:col-start-1']">
          <Button :text="t('btn.buyNow')" class="w-full" />
        </div>
      </div>
    </template>
  </Panel>
</template>

<script setup lang="ts">
const { cinema } = await useVoucherShop()

const { t } = useI18n()
const { cart } = await useVoucherCart({
  cinema: cinema.value,
  onReset: () => {
    // TODO: reset cart
  },
})

defineOptions({
  name: 'VoucherShopPanelCheckout',
})
</script>

<i18n>
de:
  btn:
    buyNow: "Jetzt kaufen"
es:
  btn:
    buyNow: "Comprar ahora"
</i18n>
