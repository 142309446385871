<template>
  <div>
    <BookingHeading :title="t('payment')" />
    <FormKit
      type="radio"
      name="selectedPayment"
      :options="paymentMethodOptions"
      :required="true"
      :classes="{
        legend: '$reset text-dark dark:text-light text-2xl font-bold mb-2',
        options: '$reset space-y-3',
        option: '$reset',
        wrapper:
          '$reset items-center gap-2 w-full input input-border input-hover p-2 data-[checked]:input-border-active',
      }"
    >
      <template #label="{ option }">
        <div class="text-sm font-medium" v-text="option.label" />
        <InlineSvg
          v-if="getPaymentIcon(option.value)"
          :key="option.value"
          v-bind="getPaymentIcon(option.value)"
          class="ml-auto flex-shrink-0"
        />
      </template>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
const { cart } = await useCart()
const { t } = useI18n()

const { getPaymentIcon } = usePaymentIcons()
const paymentMethodOptions = computed(() =>
  cart.value?.availablePaymentMethods.map(({ name }) => ({
    label: name,
    value: name,
  }))
)

defineOptions({
  name: 'CartPaymentSelection',
})
</script>

<i18n>
de:
  payment: "Zahlung"
es:
  payment: "Pago"
</i18n>
