<template>
  <Panel>
    <div v-if="strings?.headline" v-html="strings.headline" />
    <div v-if="strings?.description" v-html="strings.description" />

    <template v-if="fixedProducts.length > 0">
      <BookingHeading
        v-if="strings?.voucherProductsFixedHeadline"
        :title="strings.voucherProductsFixedHeadline"
      />
      <div
        v-if="strings?.voucherProductsFixedDescription"
        v-html="strings.voucherProductsFixedDescription"
      />
      <VoucherProductCards :voucher-products="fixedProducts" />
    </template>

    <template v-if="ticketProducts.length > 0">
      <BookingHeading
        v-if="strings?.voucherProductsTicketsHeadline"
        :title="strings.voucherProductsTicketsHeadline"
      />
      <div
        v-if="strings?.voucherProductsTicketsDescription"
        v-html="strings.voucherProductsTicketsDescription"
      />
      <VoucherProductCards :voucher-products="ticketProducts" />
    </template>
  </Panel>
</template>

<script setup lang="ts">
import { VOUCHER_PRODUCT_TYPE } from '@base/constants/voucher-shop'
import type { VoucherShopStrings } from '#gql/default'

interface Props {
  strings?: VoucherShopStrings
}

const { strings } = defineProps<Props>()
const { voucherProducts } = await useVoucherShop()

const fixedProducts = computed(() =>
  voucherProducts.value.filter(
    ({ type }) => type === VOUCHER_PRODUCT_TYPE.FIXED
  )
)

const ticketProducts = computed(() =>
  voucherProducts.value.filter(
    ({ type }) => type === VOUCHER_PRODUCT_TYPE.TICKETS
  )
)

defineOptions({
  name: 'VoucherShopPanelSelect',
})
</script>
