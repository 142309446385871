<template>
  <PanelManager v-if="isInitalised">
    <template #navigation="{ step }">
      <PanelNavigation
        v-if="step && step !== SELECT"
        :panels
        :active-panel
        :step
        :set-panel
      />
    </template>
    <template #panels="{ step }">
      <VoucherShopPanelSelect v-if="!step || step === SELECT" :strings />
      <VoucherShopPanelConfigure
        v-else-if="step === CONFIGURE && voucherProduct"
      />
      <VoucherShopPanelCart v-else-if="step === CART && cart" />
      <VoucherShopPanelCheckout v-else-if="step === CHECKOUT && cart" />

      <CartFab
        v-if="(step === SELECT || step === CONFIGURE) && cart"
        @click="setPanel(CART)"
      />
    </template>
  </PanelManager>
</template>

<script setup lang="ts">
import { VOUCHER_SHOP_PANEL } from '@base/constants/voucher-shop'
import type { Panel } from '@base/types/panel-manager'
import type {
  Cinema,
  VoucherShopStrings,
  VoucherProductType,
} from '#gql/default'

interface Props {
  cinema?: Cinema
  strings: VoucherShopStrings
  voucherProductTypes: VoucherProductType[]
}

const { t } = useI18n()
const { CONFIGURE, CART, CHECKOUT, SELECT } = VOUCHER_SHOP_PANEL
const { cinema, strings, voucherProductTypes } = defineProps<Props>()
const {
  voucherProduct,
  initialise,
  isInitalised,
  panels,
  activePanel,
  setPanel,
} = await useVoucherShop()

await initialise({
  cinema,
  voucherProductTypes,
  panels: [
    { key: SELECT, title: t('panels.select.title') },
    {
      key: CONFIGURE,
      title: t('panels.configure.title'),
      hideFromNav: true,
    },
    { key: CART, title: t('panels.cart.title') },
    { key: CHECKOUT, title: t('panels.checkout.title') },
  ] as Panel[],
})
const { cart } = await useVoucherCart({ cinema })

defineOptions({
  name: 'VoucherShop',
})
</script>

<i18n>
de:
  noVouchersAvailable: "Leider sind aktuell keine Gutscheine verfügbar."
  panels:
    configure:
      title: "Konfigurieren"
    cart:
      title: "Warenkorb"
    checkout:
      title: "Kasse"
    select:
      title: "Gutschein-Shop"
es:
  noVouchersAvailable: "Lo sentimos, no hay cupones disponibles."
  panels:
    configure:
      title: "Configurar"
    cart:
      title: "Carrito"
    checkout:
      title: "Pagar"
    select:
      title: "Tienda de regalos"
</i18n>
