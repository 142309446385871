<template>
  <div
    class="border-neutral grid h-full grid-rows-[auto,minmax(0,1fr),auto] rounded-lg border"
    @click="handleClick"
  >
    <VoucherProductImage :voucher-product :rounded="true" />
    <div
      class="prose dark:prose-invert prose-li:list-disc dark:prose-li:marker:text-light prose-li:marker:text-dark prose-li:my-1 prose-li:leading-tight px-4"
    >
      <div class="pt-2 text-lg font-bold" v-text="voucherProduct.name" />
      <div v-html="voucherProduct.description" />
    </div>

    <div class="px-4 pb-4">
      <Button :text="t('btn.configureVoucher')" class="w-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { VoucherProduct } from '#gql/default'

interface Props {
  voucherProduct: VoucherProduct
}

function handleClick() {
  configure({ voucher: voucherProduct })
  setPanel(VOUCHER_SHOP_PANEL.CONFIGURE, {
    voucherId: voucherProduct.id,
  })
}

const { voucherProduct } = defineProps<Props>()
const { configure, setPanel } = await useVoucherShop()
const { t } = useI18n()

defineOptions({
  name: 'VoucherProductCard',
})
</script>

<i18n>
de:
  btn:
    configureVoucher: "Gutschein konfigurieren"
</i18n>
