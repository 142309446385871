<template>
  <div
    ref="container"
    data-voucher-product-cards
    class="group-data-[default-horizontal-spacing=true]:container-bleed"
  >
    <HorizontalMediaScroller
      :slides="voucherProducts"
      :hide-buttons="false"
      :per-page="slidesVisible"
      :classes="{
        slide: cssClasses.slide,
      }"
      image-selector="[data-image]"
    >
      <template #default="{ slide: voucherProduct }">
        <VoucherProductCard :key="voucherProduct.id" :voucher-product />
      </template>
    </HorizontalMediaScroller>
  </div>
</template>

<script lang="ts" setup>
import type { VoucherProduct } from '#gql/default'

interface Props {
  voucherProducts: VoucherProduct[]
}

defineProps<Props>()

const container = ref(null)
const { width } = useElementSize(container)

const slidesVisible = computed(() => {
  if (width.value >= TAILWIND_CONTAINER_QUERY.SIZE_XL) {
    return 3
  } else {
    return 2
  }
})

const cssClasses = {
  container:
    'flex group relative px-3 sm:px-6 xl:px-8 2xl:px-8 overflow-hidden',
  slide: 'flex flex-col grow-0 shrink-0 px-1 sm:px-2 basis-1/2 @xl:basis-1/3',
}

defineOptions({
  name: 'VoucherProductCards',
})
</script>
