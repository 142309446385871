<template>
  <div class="mr-2 mt-2">
    <FormKit
      :id="formId"
      v-model="formData"
      :name="formId"
      type="form"
      :actions="false"
      :classes="{
        form: 'relative',
      }"
      @submit="persistInUrl"
    >
      <Button
        :icon="SVG_ICON.MAGNIFY"
        :size="BUTTON_SIZE.BASE"
        :theme="BUTTON_THEME.TRANSPARENT"
        :title="placeholder ?? t('placeholder.search')"
        class="absolute left-0.5 top-0.5 z-10"
        @click="setFocus"
      />

      <Button
        v-if="!isEmpty"
        :icon="SVG_ICON.CLOSE"
        :size="BUTTON_SIZE.BASE"
        :theme="BUTTON_THEME.TRANSPARENT"
        class="absolute right-0.5 top-0.5 z-10"
        @click="reset"
      />

      <FormKit
        :id="`${formId}-${URL_FILTER.SEARCH}`"
        type="text"
        :name="URL_FILTER.SEARCH"
        autocomplete="off"
        :delay="200"
        :placeholder="placeholder ?? t('placeholder.search')"
        :data-always-open="alwaysOpen"
        :classes="{
          wrapper:
            '$reset group/form button button-secondary hover:bg-transparent button-base !w-auto !min-w-0 px-0',
          input:
            '$reset border-none group-data-[empty]/form:w-10 outline-none bg-transparent pl-9 focus:pr-9 data-[always-open=true]:!w-64 dark:data-[always-open=true]:!placeholder-light data-[always-open=true]:!placeholder-dark focus:!w-64 w-64 placeholder-transparent focus:placeholder-dark dark:focus:placeholder-light duration-200 ease-in-out',
        }"
        @input="handleInput"
      />
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  urlPrefix?: string
  preselected?: string
  disabled?: boolean
  placeholder?: string
  alwaysOpen?: boolean
}

const props = defineProps<Props>()
const { t } = useI18n()

const {
  formId,
  formData,
  persistInUrl,
  setData,
  urlValues,
  isEmpty,
  resetForm,
} = useFiltersForm({
  urlPrefix: props.urlPrefix,
  filterName: URL_FILTER.SEARCH,
  resetFilters: [URL_FILTER.PAGE],
})

function setFocus() {
  const inputElement = document.querySelector(`#${formId} input`)
  if (inputElement instanceof HTMLElement) {
    inputElement.focus()
  }
}

function reset() {
  setData('')
  resetForm()
  setFocus()
  persistInUrl({ [URL_FILTER.SEARCH]: undefined })
}

function handleInput(e: string) {
  persistInUrl({ [URL_FILTER.SEARCH]: e })
}

if (urlValues.value?.[0] || props.preselected) {
  setData(urlValues.value?.[0] ?? props.preselected)
}

watch(urlValues, () => {
  setData(urlValues.value[0])
})

defineOptions({
  name: 'FiltersSearch',
})
</script>

<i18n>
de:
  placeholder:
    search: Suche
es:
  placeholder:
    search: Buscar
</i18n>
