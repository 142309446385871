<template>
  <Panel>
    <template #default="{ cssClasses }">
      <div v-if="cart?.voucherProducts.length" :class="cssClasses.grid">
        <CartCountdown
          v-if="cart?.expiresAt"
          :expires-at="cart.expiresAt"
          :class="[cssClasses.sidebar, 'md:col-start-2']"
        />

        <div
          :class="[
            cssClasses.content,
            'md:col-start-1 md:row-span-2 md:row-start-1 md:w-full',
          ]"
        >
          <div
            class="dark:bg-neutral-dark-2 bg-neutral-light-1 text:dark rounded-md px-4 py-2"
          >
            <VoucherCartProduct
              v-for="cartVoucherProduct in cart?.voucherProducts"
              :key="cartVoucherProduct.id"
              :cart-voucher-product
            />
          </div>
        </div>

        <div
          v-if="cart"
          :class="[cssClasses.sidebar, 'md:col-start-2 md:row-start-2']"
        >
          <CartPriceInfo v-if="cart" :price="cart.price" />
          <CartAvailablePaymentMethods
            :available-payment-methods="cart.availablePaymentMethods"
          />
          <Button
            :text="t('btn.checkout')"
            class="w-full"
            @click="setPanel(VOUCHER_SHOP_PANEL.CHECKOUT)"
          />
        </div>
      </div>
      <CartEmpty v-else :text="t('btn.back')" @back="setInitialPanel" />
    </template>
  </Panel>
</template>

<script setup lang="ts">
import { VOUCHER_SHOP_PANEL } from '@base/constants/voucher-shop'

const { t } = useI18n()
const { add: addMessage } = useMessage()
const { setPanel, setInitialPanel, cinema } = await useVoucherShop()
const { cart } = await useVoucherCart({
  cinema: cinema.value,
  onReset: () => {
    addMessage({
      message: 'Warenkorb wurde geleert',
      type: MESSAGE_TYPE.MODAL,
    })
    setInitialPanel()
  },
})

defineOptions({
  name: 'VoucherShopPanelCart',
})
</script>

<i18n>
de:
  btn:
    checkout: "Kasse"
    back: "Zum Gutschein-Shop"
es:
  btn:
    checkout: "Pagar"
    back: "Volver al Shop"
</i18n>
