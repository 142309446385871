import type { Cart, CartType, Cinema } from '#gql/default'

export default async function useCart({
  onReset,
}: { onReset?: () => void } = {}) {
  const cart = useState<Cart | null>('cart', () => null)
  const cartId = useState<string | undefined>('cartId', () => undefined)

  const route = useRoute()
  const router = useRouter()

  const expiresAt = computed(() => cart.value?.expiresAt ?? null)
  const availablePaymentMethods = computed(
    () => cart.value?.availablePaymentMethods ?? []
  )

  useCountdown({
    expiresAt,
    onExpired: resetCart,
  })

  async function fetchCart(cartId?: string) {
    if (cart.value || !cartId) {
      return cart.value
    }

    const { data } = await useAsyncGql('FetchCart', {
      cartId,
    })

    if (!data.value?.cart) {
      resetCart()
      return
    }

    cart.value = data.value.cart as Cart
  }

  function resetCart() {
    cart.value = null
    cartId.value = undefined
    updateQueryParam()
    onReset?.()
  }

  async function ensureCart({
    cinema,
    type,
  }: {
    cinema?: Cinema
    type: CartType
  }): Promise<Cart> {
    if (!cart.value || !cartId.value) {
      const result = await GqlCartCreate({
        cinemaId: cinema?.id,
        type,
      })

      if (!result?.cartCreate) {
        throw new Error('Failed to create cart')
      }

      cartId.value = result.cartCreate.id
      cart.value = result.cartCreate as Cart
      updateQueryParam()
    }

    return cart.value
  }

  function updateQueryParam() {
    if (cartId.value) {
      router.push({ query: { ...route.query, cartId: cartId.value } })
    } else {
      const query = { ...route.query }
      delete query.cartId
      router.push({ query })
    }
  }

  if (!cart.value && route.query.cartId) {
    await fetchCart(route.query.cartId as string)
  }

  return {
    cart,
    ensureCart,
    fetchCart,
    expiresAt,
    availablePaymentMethods,
  }
}
