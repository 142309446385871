<template>
  <Panel>
    <template #default="{ cssClasses }">
      <div :class="cssClasses.grid">
        <div :class="[cssClasses.content, 'order-2 md:order-1']">
          <VoucherProductFormConfigure />
        </div>

        <div :class="[cssClasses.sidebar, 'order-1 md:order-2']">
          <VoucherProductImage
            :voucher-product="voucherProduct!"
            :render-above-the-fold="true"
            class="hidden md:block"
          />
          <h3 class="text-lg font-bold" v-text="t('terms')" />
          <div
            class="prose dark:prose-invert prose-li:list-disc dark:prose-li:marker:text-light prose-li:marker:text-dark prose-li:my-1 prose-li:leading-tight"
            v-html="voucherProduct!.terms"
          />
        </div>
      </div>
    </template>
  </Panel>
</template>

<script setup lang="ts">
const { t } = useI18n()

const { voucherProduct } = await useVoucherShop()

defineOptions({
  name: 'VoucherShopPanelConfigure',
})
</script>

<i18n>
de:
  terms: "Konditionen"
es:
  terms: "Términos"
</i18n>
