export function usePaymentIcons() {
  const { design } = useDesign()

  const paymentMethodsIcons = computed(() => ({
    Paypal: {
      name: design.value?.isDarkTheme
        ? PAYMENT_ICONS.PAYPAL_DARK
        : PAYMENT_ICONS.PAYPAL_LIGHT,
      class: 'h-6',
    },
    Amazon: {
      name: design.value?.isDarkTheme
        ? PAYMENT_ICONS.AMAZON_DARK
        : PAYMENT_ICONS.AMAZON_LIGHT,
      class: 'h-5',
    },
    GooglePay: {
      name: design.value?.isDarkTheme
        ? PAYMENT_ICONS.GPAY_LIGHT
        : PAYMENT_ICONS.GPAY_DARK,
      class: 'h-6',
    },
    Kps: {
      name: PAYMENT_ICONS.CREDITCARD,
      class: 'h-6',
    },
    Sofort: {
      name: PAYMENT_ICONS.UNZER,
      class: 'h-4',
    },
    Klarna: {
      name: PAYMENT_ICONS.KLARNA,
      class: 'h-8',
    },
    Loyalty: {
      name: PAYMENT_ICONS.LOYALTY,
      class: 'h-8',
    },
  }))

  function getPaymentIcon(key: keyof typeof paymentMethodsIcons.value) {
    const icon = paymentMethodsIcons.value[key]
    return {
      ...icon,
      type: INLINE_SVG_TYPE.PAYMENT_ICON,
      class: `inline-flex w-auto shrink-0 ${icon.class}`,
    }
  }

  return {
    getPaymentIcon,
  }
}
