<template>
  <div
    class="grid grid-cols-[minmax(0,1fr),auto] gap-x-6 gap-y-2 py-4 transition-opacity duration-300"
    :class="{ 'pointer-events-none opacity-25': pending }"
  >
    <div
      v-text="
        formatQuantityAndName(
          cartVoucherProduct.quantity,
          cartVoucherProduct.voucherProduct.name
        )
      "
    />
    <div v-text="n(cartVoucherProduct.price.total, 'currency')" />
    <div class="col-span-2">
      <div v-text="t('design', { voucherDesign })" />
      <div v-text="t('amount', { voucherAmount })" />
      <div v-for="({ text }, i) in cartVoucherProduct.customisations" :key="i">
        {{ text }}
      </div>
    </div>

    <VoucherProductFormUpdateInCart
      :cart-voucher-product
      class="col-span-2 mt-4"
    />
  </div>
</template>

<script setup lang="ts">
import type { CartVoucherProduct } from '#gql/default'

interface Props {
  cartVoucherProduct: CartVoucherProduct
}

const { cartVoucherProduct } = defineProps<Props>()
const { cinema } = await useVoucherShop()
const { pending } = await useVoucherCart({ cinema: cinema.value })
const { t, n } = useI18n()

const voucherDesign = computed(
  () =>
    cartVoucherProduct.voucherProduct.voucherDesigns.find(
      (design) => design.id === cartVoucherProduct.voucherDesignId
    )?.name
)

const voucherAmount = computed(() =>
  n(cartVoucherProduct.selectedAmount, 'currency')
)

defineOptions({
  name: 'VoucherCartProduct',
})
</script>

<i18n>
de:
  design: "Design: {voucherDesign}"
  amount: "Amount: {voucherAmount}"
es:
  design: "Design: {voucherDesign}"
  amount: "Amount: {voucherAmount}"
</i18n>
