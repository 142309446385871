<template>
  <ImageWrapper :image="imageObject" :config="imageConfig" :classes>
    <template #default="{ classList }">
      <ImagePlaceholder
        :icon="SVG_ICON.GALLERY_IMAGE"
        :class="[...classList]"
      />
    </template>
  </ImageWrapper>
</template>

<script setup lang="ts">
import type { VoucherProduct } from '#gql/default'

interface Props {
  voucherProduct: VoucherProduct
  rounded?: boolean
}

const { voucherProduct, rounded = false } = defineProps<Props>()

const classes = computed(() => ({
  placeholder: {
    'w-full h-full': true,
    'rounded-t-lg': rounded,
  },
  image: {
    'w-full h-auto': true,
    'rounded-t-lg': rounded,
  },
  container: {
    'aspect-[4/3] overflow-hidden': true,
    'rounded-t-lg': rounded,
  },
}))

const imageObject = getImage(voucherProduct, 'image', voucherProduct.name)
const config = useAppConfig()
const imageConfig = computed(() => config.images.voucher.card)

defineOptions({
  name: 'VoucherProductImage',
})
</script>
