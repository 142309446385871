<template>
  <div
    class="dark:bg-neutral-dark-2 bg-neutral-light-1 dark:text-neutral-light-1 text-neutral-dark-1 flex min-h-48 w-full flex-col items-center justify-center space-y-6 rounded-md p-8"
  >
    <InlineSvg :name="SVG_ICON.EMPTY_CART" class="size-16 fill-current" />
    <span v-text="t('emptyCart')" />
    <Button :text @click="emit('back')" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  text?: string
}

const { t } = useI18n()
const { text } = defineProps<Props>()
const emit = defineEmits(['back'])

defineOptions({
  name: 'CartEmpty',
})
</script>

<i18n>
de:
  emptyCart: "Der Warenkorb ist leer"
es:
  emptyCart: "Su carrito está vacío"
</i18n>
